<template>
  <section class="container-fluid p-0 header-multi-bg" >
    <b-container fluid class="header-multi">
      <transition name="bannerMulti">
        <b-row fluid v-for="item in $props.items" :key="item.id">
          <b-col class="d-none d-md-block d-lg-block p-0" md="5">
            <div :style="'height:100%;margin:0;' +
                  'background-image:url('+item.image+');' +
                  'background-size:cover;' +
                  'background-position:left 0;'">
            </div>
          </b-col>
          <b-col md="7" class="text-left right-side">
            <div itemscope :itemtype="'https://schema.org/'+schema">
              <h1 class="title pt-2 pl-2 pr-2" itemprop="name" v-html="item.title+'<span class=\'dline_lg\'></span>'"></h1>
              <h2 class="subtitle pl-2 pb-2 mb-4" v-html="item.subtitle" itemprop="keywords"></h2>
                <div style="display:inline;margin-right:0.5rem;" class="pl-2 text-left" v-for="button in item.buttons" :key="button.id">
                  <ButtonMeetingComponent class="pb-2 pt-2 lead"  v-if="button.type=='meeting'" :text="button.text" :variant="button.variant" />
                  <ButtonComponent v-else class="pb-2 pt-2 lead d-block d-sm-inline-block" :text="button.text" :icon="button.icon" :variant="button.variant" :href="button.link"></ButtonComponent>
                </div>
              <div class="pt-4"><label class="note" v-html="item.note"></label></div>
            </div>
          </b-col>
        </b-row>
      </transition>
    </b-container>
  </section>
</template>

<script>
import ButtonComponent from '../buttons/ButtonComponent.vue';
import ButtonMeetingComponent from "../buttons/ButtonMeetingComponent.vue";
export default {
  name: 'BannerMultiProductComponent',
  components: { ButtonMeetingComponent, ButtonComponent },
  props: {
    items: Array,
    schema: String,
    background: {
      type: String,
      default: '#fefefe'
    }
  },
  data() {
    return {
      item_id:1
    }
  },
  methods: {
    transition(){

    }
  },
  mounted() {
    this.transition()
  }
}
</script>
<style lang="scss" scoped>
@import "../../../systemico.ui/basic.scss";
  .header-multi-bg{
    background: v-bind( background );
  }
  .header-multi {
    height: auto;
    background: #fefefe;
    border-bottom: 1rem solid #FF8028;
    /**border-bottom-left-radius:25%;**/
    /**border-bottom-right-radius: 25%;**/
    overflow: hidden;
    .right-side{
      -webkit-box-shadow: -28px 0px 47px -12px rgba(0,0,0,0.40);
      -moz-box-shadow: -28px 0px 47px -12px rgba(0,0,0,0.40);
      box-shadow: -28px 0px 47px -12px rgba(0,0,0,0.40);
      padding-bottom: 2rem;
    }
    .title {
      margin-top: 1rem;
      font-size: calc(3rem + 1vw);
      line-height: calc(3.5rem + 1vw);
      font-weight: bold;
      color: #0b1c98;
    }

    .subtitle {
      font-size: calc(1.3rem + 1vw);;
      line-height: calc(1.5rem + 1vw);;
      color: #777777;
      width: 90%;
    }

    .note {
      font-size: 1rem;
      color: #777777;
      font-family: $font-quicksand;
      margin-left: 1rem;
    }

    /** MEDIA QUERY FOR VERTICAL IPAD **/
    @media (max-width: 1024px) {
      .title {
        margin-top: 1rem;
        font-size: calc(2.6rem + 1vw);
        line-height: calc(3rem + 1vw);
      }
      .subtitle {
        font-size: calc(1rem + 1vw);
        line-height: calc(1.2rem + 1vw);
      }
      .note {
        font-size: 0.8rem;
      }
    }
  }
</style>
