<template>
  <div class="home">
    <HeaderSystemicoComponent :menuActive="true" :lang="$props.lang" class="header_nav__active p-0" />
    <WavesComponent style="1" background="#dddddd" color="#171e44" />
    <!--<b-img class="logo" src="https://cdn.systemico.net/systemico/logos/logo-systemico--white-2.svg" ></b-img>-->
    <!--<div class="intro" style="margin-top: -300px;">
      <h1 class="sublogo">
         {{ title[$props.lang] }}
      </h1>
      <b-container fluid class="selector">
        <h1 class="title">{{country_choose[$props.lang]}}</h1>
        <b-row class="justify-content-md-center" align-h="center">
          <b-col md="3" sm="12" v-for="continent in continents[$props.lang]" :key="continent.id" v-if="continent.visible">
              <h1 class="continent" v-show="false">{{continent.name}}</h1>
              <ul v-for="country in continent.countries" :key="country.id">
                <li class="country" v-show="country.visible"><a  :href="country.url">
                    <b-img :src="country.icon" class="float-left" height="30px" alt="flag"></b-img>
                  {{country.name}}</a></li>
              </ul>
          </b-col>
        </b-row>
      </b-container>
    </div>-->
    <DeckComponent id="what" style="margin-top: -300px"
                   :title="title[$props.lang]"
                   :description="description[$props.lang]"
                   variant="blue"
                   :items="services[$props.lang]"
    />
    <BannerTipComponent
      :title="tipTitle[$props.lang]"
      :message="tipMessage[$props.lang]"
      background="#fefefe"
      :buttonLabel="tipButton[$props.lang]"
      button-icon="question-circle-fill"
      @click="support"
    />
    <FooterSystemicoComponent :lang="$props.lang" />
  </div>
</template>

<script>
import HeaderSystemicoComponent from '../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue'
import FooterSystemicoComponent from '../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import products from '@/components/landing/products.vue'
import DeckComponent from '@/../../systemico.ui/components/cards/DeckComponent.vue'
import WavesComponent from '../../../systemico.ui/components/waves/WavesComponent.vue'
import BannerTipComponent from '../../../systemico.ui/components/banners/BannerTipComponent.vue'

export default {
  name: 'Home',
  components: {
    DeckComponent,
    products,
    FooterSystemicoComponent,
    HeaderSystemicoComponent,
    WavesComponent,
    BannerTipComponent
  },
  props: {
    lang: String
  },
  data: function () {
    return {
      title: {
        ES: 'Software inteligente para mejorar y simplificar negocios.',
        EN: 'Smart software to improve and simplify businesses.'
      },
      description: {
        ES: 'Ofrecemos soluciones para mejorar tu productividad diaria.',
        EN: 'We offer solutions to improve your daily productivity.'
      },
      country_choose: {
        ES: 'Selecciona tu país',
        EN: 'Select your country'
      },
      continents: {
        EN: [
          {
            id: 1,
            name: 'America',
            countries: [
              { id: 1, name: 'Canada', flag: '', url: '/en-ca', icon: 'https://cdn.systemico.net/systemico/flags/flag-canada.svg', visible: true },
              { id: 2, name: 'Colombia', flag: '', url: '/es-co', icon: 'https://cdn.systemico.net/systemico/flags/flag-colombia.svg', visible: true },
              { id: 3, name: 'United States', flag: '', url: '/en-us', icon: 'https://cdn.systemico.net/systemico/flags/flag-usa.svg', visible: true },
              { id: 4, name: 'Global', flag: '', url: '/en-us', icon: 'https://cdn.systemico.net/systemico/flags/world.svg', visible: true }
            ],
            visible: true
          }
          /** {
                id: 2,
                name: 'Africa',
                countries: [
                  { id: 1, name: 'Ecuatorial Guinea', flag: '', url: '/es-gq', visible:true }
                ],
                visible: true
              },
              {
                id: 3,
                name: 'Asia',
                countries: [
                  { id: 1, name: 'Japan', flag: '', url: '/jp-jp', icon: 'https://cdn.systemico.net/systemico/flags/flag-japan.svg', visible:true }
                ],
                visible: true
              },
              {
                id: 4,
                name: 'Europe',
                countries: [
                  { id: 1, name: 'Spain', flag: '', url: '/es-es',icon: 'https://cdn.systemico.net/systemico/flags/flag-spain.svg', visible:true }
                ],
                visible: true
              },
              {
                id: 4,
                name: 'Oceania',
                countries: [
                  { id: 1, name: 'Australia', flag: '', url: '/en-au/', icon: 'https://cdn.systemico.net/systemico/flags/flag-australia.svg', visible:true }
                ],
                visible: true
              }**/
        ],
        ES: [
          {
            id: 1,
            name: 'America',
            countries: [
              { id: 1, name: 'Canadá', flag: '', url: '/en-ca', icon: 'https://cdn.systemico.net/systemico/flags/flag-canada.svg', visible: true },
              { id: 2, name: 'Colombia', flag: '', url: '/es-co', icon: 'https://cdn.systemico.net/systemico/flags/flag-colombia.svg', visible: true },
              { id: 3, name: 'Estados Unidos', flag: '', url: '/en-us', icon: 'https://cdn.systemico.net/systemico/flags/flag-usa.svg', visible: true },
              { id: 4, name: 'Global', flag: '', url: '/en-us', icon: 'https://cdn.systemico.net/systemico/flags/world.svg', visible: true }
            ],
            visible: true
          }
          /** {
            id: 2,
            name: 'Africa',
            countries: [
              { id: 1, name: 'Ecuatorial Guinea', flag: '', url: '/es-gq', visible:true }
            ],
            visible: true
          },
          {
            id: 3,
            name: 'Asia',
            countries: [
              { id: 1, name: 'Japan', flag: '', url: '/jp-jp', icon: 'https://cdn.systemico.net/systemico/flags/flag-japan.svg', visible:true }
            ],
            visible: true
          },
          {
            id: 4,
            name: 'Europe',
            countries: [
              { id: 1, name: 'Spain', flag: '', url: '/es-es',icon: 'https://cdn.systemico.net/systemico/flags/flag-spain.svg', visible:true }
            ],
            visible: true
          },
          {
            id: 4,
            name: 'Oceania',
            countries: [
              { id: 1, name: 'Australia', flag: '', url: '/en-au/', icon: 'https://cdn.systemico.net/systemico/flags/flag-australia.svg', visible:true }
            ],
            visible: true
          }**/
        ]
      },
      lines: [
        { id: 1, name: 'Products', description: 'Products ready to use.', link: '/products' },
        { id: 2, name: 'Software Development', description: 'Custom development for your needs.', link: '/development' },
        { id: 3, name: 'Integrations & Plugins', description: 'Code to integrate and increase your products functionalities.', link: '/development' }
      ],
      services: {
        EN: [
          { id: 1, title: 'Software as a service.', description: 'Simple software to improve your processes and team. Generate cool experiences to your clients, teams and providers.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-25.png', link: '/products', button: 'Learn more', buttonIcon: 'arrow-right' },
          { id: 2, title: 'Custom Development.', description: 'Development on demand for your startup, small business or company. API, Dashboards, web applications, bots and more.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-1.png', link: '/development', button: 'Learn more', buttonIcon: 'arrow-right' },
          { id: 3, title: 'Data Analytics.', description: 'Identify patterns and sources to obtain data and transform it into information to learn, improve your business and more impact.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-21.png', link: '/development/data_analytics', button: 'Learn more', buttonIcon: 'arrow-right' },
          { id: 4, title: 'Code Ready.', description: 'Set options of Open Data, Plugins, Libraries, Components, Extensions, Cron and more to help your team with clean code to integrate directly.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-3.png', link: '/code', button: 'Learn more', buttonIcon: 'arrow-right' }
        ],
        ES: [
          { id: 1, title: 'Software como servicio.', description: 'Software simple para mejorar tus procesos y equipo. Genera experiencias geniales a tus clientes, equipos y proveedores.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-25.png', link: '/products', button: 'Me interesa', buttonIcon: 'arrow-right' },
          { id: 2, title: 'Desarrollo a medida.', description: 'Desarrollo a demanda para tu startup, pequeño negocio o empresa. API, Dashboards, aplicaciones web, bots y más.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-1.png', link: '/development', button: 'Me interesa', buttonIcon: 'arrow-right' },
          { id: 3, title: 'Analítica de datos.', description: 'Identifica patrones y fuentes para obtener datos y transformarlos en información para aprender, mejorar tu negocio y más impacto.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-21.png', link: '/development/data_analytics', button: 'Me interesa', buttonIcon: 'arrow-right' },
          { id: 4, title: 'Código listo.', description: 'Opciones de Open Data, Plugins, Librerías, Componentes, Extensiones, Cron y más para ayudar a tu equipo con código limpio para integrar.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-3.png', link: '/code', button: 'Me interesa', buttonIcon: 'arrow-right' }
        ]
      },
      tipTitle: {
        ES: '<b>¡Cuenta con nosostros!</b>, utiliza nuestro botón naranja para solicitar ayuda permanente.',
        EN: '<b>Count on us!</b>, use our orange button to request permanent help.'
      },
      tipMessage: {
        ES: 'Si tienes alguna duda, pregunta o sugerencia, <b>no dudes en contactarnos utilizando este canal</b> ' +
          'que encontrarás en todos nuestros productos, servicios, sitios web y aplicaciones móviles.',
        EN: 'If you have any questions, questions or suggestions, <b>do not hesitate to contact us using this channel</b> ' +
          'that you will find in all our products, services, websites and mobile applications.'
      },
      tipButton: {
        ES: 'Solicitar Ayuda',
        EN: 'Request Help'
      },
      dataSupport: {
        EN: {
          tag: 'Define your information to get help.',
          title: 'Send your request, to receive help as soon as posible.',
          content: '<b>Systemico</b> develop and support simple, smart and cool software. Each opinion ' +
              'is our engine to motivate us to change and improve.<br><br><i>Send your request, feedback or complaint to learn about your needs. </i> <b>Thanks.</b>',
          image: 'https://cdn.systemico.net/systemico/web/contact/headers/header-1.svg'
        },
        ES: {
          tag: 'Define tu información para obtener ayuda.',
          title: 'Describe lo que necesitas y te ayudaremos lo antes posible.',
          content: '<b>Systemico</b> desarrolla y soporta software simple, inteligente y genial. Cada opinión ' +
              'es nuestro motor para motivarnos a cambiar y mejorar.<br><br><i>Envía tu solicitud, comentario o queja para conocer tus necesidades. </i><b>Gracias</b>',
          image: 'https://cdn.systemico.net/systemico/web/contact/headers/header-1.svg'
        }
      },
    }
  },
  methods: {
    support: function () {
      window.suppmail.$children[0].loadmodalcontact(this.dataSupport, this.$props.lang)
    }
  },
  beforeMount () {
    if (this.lang === undefined || this.lang === '{{LANG}}') {
      this.lang = 'en'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../systemico.ui/variables.scss";
  .home{
    position:absolute;
    min-height: 100%;
    width: 100%;
    font-family:$font-quicksand;
  }
  .intro{
    width: 100%;
    min-height: 100%;
    background:$color-blue--dark;
    background-image: url("https://cdn.systemico.net/systemico/images/image-banner-3.jpg");
    background-position: top;
    background-size: cover;
    background-repeat:no-repeat;
    text-align:center;
    padding-bottom:2em;
  }
  .logo{
    margin-top:10em;
    width: 20%;
    min-width:300px;
  }
  .sublogo{
    font-size: 3rem;
    text-shadow:1px 1px #222222;
    color:$color-white;
    padding-top:3.5rem;
    max-width:80%;
    margin:auto;
  }
  .title{
    margin-bottom:1em;
    font-size:1.5em;
    color:#222222;
    text-shadow:1px 1px #fefefe;
    padding:1em;
    margin-top:1em;
    font-weight: bold;
  }
  .selector{
    text-align: center;
    margin:auto;
    padding-left:2em;
    padding-right:2em;
  }
  .continent{
    font-size:2em;
    color:#f8f8f8;
  }
  .country{
    font-size:1.2rem;
    background: $color-white;
    padding:0.5rem;
    margin-top:0.5rem;
    list-style:none;
    border-radius:1.5em;
    a{
      color:#444444;
      text-decoration:none;
    }
    a:hover{
      color:#222222;
      font-weight:bold;
      text-decoration:none;
    }
  }
  country:hover{
    background: #fefefe;
    color:#444444;
  }
</style>
