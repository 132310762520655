<template>
  <div class="home pt-5">
    <HeaderSystemicoComponent :menuActive="true" lang="es" country="colombia" class="header_nav__active p-0" />
    <BannerMultiProductComponent :items="banner_items" country="COLOMBIA" lang="ES" />
    <products id="products" :title="productsTitle" background="#f8f8f8" textColor="#444444"  lang="es" currency="cop" country="colombia" alone="products"/>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f8f8f8" fill-opacity="1" d="M0,224L480,192L960,96L1440,288L1440,0L960,0L480,0L0,0Z"></path></svg>
    <products id="services" :title="servicesTitle" :description="productsDescription" background="transparent" textColor="#222222" lang="ES" currency="cop" country="colombia" alone="services" style="margin-top: -100px"/>
    <svg style="margin-top:-200px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f8f8f8" fill-opacity="1" d="M0,224L480,192L960,96L1440,288L1440,320L960,320L480,320L0,320Z"></path></svg>
    <BannerBenefitsComponent
          title="<b>Soñamos en grande</b>, para que nuestro país sea mejor."
          description="Hola "
          :items="benefits"
          background="#f8f8f8"
          titleColor="#222222"
          titleItemColor="#444444"
          textColor="#666666"
    />
    <rocket/>
    <clients/>
    <!--<software/>
    <ceo/>-->
    <methodology/>
    <team/>
    <allies/>
    <news />
    <testimonials lang="es"/>
    <!--<contact/>-->
    <FooterSystemicoComponent lang="ES"/>
    <!-- MODALS -->
    <!--<modalTranslate @changeLanguage="changeLanguage"/>-->
    <modalPopup />
    <CookieConsent lang="ES" />
  </div>
</template>

<script>
// import headerC from '@/../../systemico.ui/components/header.vue'
// import banner from '@/components/landing/banner.vue'
import products from '@/components/landing/products.vue'
import software from '@/components/landing/software.vue'
import services from '@/components/landing/services.vue'
import methodology from '@/components/landing/methodology.vue'
import rocket from '@/components/landing/rocket.vue'
import team from '@/components/landing/team.vue'
// import technologies from '@/components/landing/technologies.vue'
import BannerMultiProductComponent from '@/../../systemico.ui/components/banners/BannerMultiProductComponent.vue'
import BannerBenefitsComponent from '@/../../systemico.ui/components/banners/BannerBenefitsComponent.vue'
import CookieConsent from '@/../../systemico.ui/components/headers/CookieConsent.vue'
import ceo from '@/components/landing/ceo.vue'
import clients from '@/components/landing/clients.vue'
import news from '@/components/landing/news.vue'
import testimonials from '@/components/landing/testimonials.vue'
import allies from '@/components/landing/allies.vue'
import contact from '@/components/landing/contact.vue'
// import pradek from '@/components/landing/pradek.vue'
import FooterSystemicoComponent from '@/../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
//import modalTranslate from '@/components/modals/modalTranslate.vue'
import modalPopup from '@/components/modals/modalPopup.vue'
import social from '@/../../systemico.ui/components/social.vue'
import HeaderSystemicoComponent from "../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
  name: 'Home_ES_CO',
  data: function () {
    return {
      headerFixed: false,
      banner_items: [
        {
          id: 1,
          title: 'Simplifica tu negocio con <b class="text-dark">Software de Systemico</b>',
          note: '<u>Elige lo que más te ayude</u>, <b>software a la medida</b> <i>es 100% adaptado a tus procesos</i>, <b>software listo</b> <i>te permite aprovechar la experiencia con proceso predefinidos</i>.',
          subtitle: '<b>tecnologías para Colombianos</b> que quieren <b style="font-weight: bold;">superar los retos actuales del país y el mundo</b>.',
          image: 'https://cdn.systemico.net/systemico/web/headers/png/header_9.png',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Catálogo de Productos', variant: 'primary', link: '#products', icon:'patch-check-fill' },
            { id: 2, text: 'Desarrollo a medida', variant: 'danger', link: '#services', icon:'code-square' }
          ],
          show: true
        }
      ],
      productsTitle:{
        ES: 'Catálogo de Productos',
        EN: 'Products Portfolio'
      },
      productsDescription: {
        ES: 'Ofrecemos paquetes por horas para desarrollo gradual de tus productos o servicios basados en software.',
        EN: 'We offer hourly packages for the gradual development of your software-based products or services.'
      },
      servicesTitle: {
        ES: 'Servicios de Desarrollo a Medida',
        EN: 'Custom Development Services'
      },
      benefits: [
        { id: 1, title: 'Soporte Permanente', description: 'Equipo experto que garantiza el cumplimiento de las metas del servicio.', icon: 'https://cdn.systemico.net/systemico/icons/icon--soporte.svg' },
        { id: 2, title: 'Experiencia', description: '17 años en el mercado con aprendizajes que fortalecieron nuestro enfoque, resiliencia y visión.', icon: 'https://cdn.systemico.net/systemico/icons/icon-code.svg' },
        { id: 3, title: 'Empatía', description: 'Orgullosamente Colombianos que vive y enfrentan los retos de nuestro entorno y economía.', icon: 'https://cdn.systemico.net/systemico/icons/icon--cool.svg' },
        { id: 4, title: 'Confianza', description: 'Clientes y aliados que garantizan nuestra experiencia y motivación.', icon: 'https://cdn.systemico.net/systemico/icons/icon-check--orange.svg' }
      ]
    }
  },
  components: {
    HeaderSystemicoComponent,
    BannerBenefitsComponent,
    BannerMultiProductComponent,
    products,
    software,
    methodology,
    rocket,
    team,
    ceo,
    clients,
    news,
    testimonials,
    allies,
    contact,
    FooterSystemicoComponent,
    //modalTranslate,
    modalPopup,
    social,
    CookieConsent
  },
  beforeMount () {
    /* if(this.$route.params.lang){
      if(this.$route.params.lang=='es' || this.$route.params.lang=='en'){
        localStorage.setItem("userLanguage", this.$route.params.lang);
        this.changeLanguage(this.$route.params.lang)
      }else{
        localStorage.setItem("userLanguage", 'en');
        this.changeLanguage('en')
      }
    }else{
      localStorage.setItem("userLanguage", 'en');
      this.changeLanguage('en')
    } */
  },
  methods: {
    headerReact: function () {
      /** const header = document.getElementsByClassName('headerUi')
      if (event.target.scrollTop > 0 && this.headerFixed === false) {
        header[0].classList.remove('headerUi--show')
        header[0].classList.add('headerUi--active')
        this.headerFixed = true
      } else if (this.headerFixed === true && event.target.scrollTop === 0) {
        header[0].classList.remove('headerUi--active')
        header[0].classList.add('headerUi--show')
        this.headerFixed = false
      }**/

      // TODO: let stateLocalStorage = localStorage.getItem("modalWePower")

      // console.log(event.target.scrollTop)

      // if (event.target.scrollTop > 2620 && window.screen.width > 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }

      // if (event.target.scrollTop > 5200 && window.screen.width < 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }
    },
    typing: function () {
      this.$refs.banner.traduccion()
    },
    changeLanguage: function (idioma) {
      this.$refs.header.changeLanguage(idioma)
    }
  }
}
</script>
<style>
@import "../../../systemico.ui/basic.scss";
</style>
