<template>
  <div id="app">
    <main>
      <router-view :lang="lang"/>
    </main>
    <!--<AccessibilityToolbar v-show="$route.name!=='Home'" />-->
    <CookieConsent :lang="lang" />
  </div>
</template>

<script>
import AccessibilityToolbar from '../../accessibility-toolbar/src/AccessibilityToolbar.vue'
import HeaderSystemicoComponent from '../../systemico.ui/components/headers/HeaderSystemicoComponent.vue'
import BannerMultiProductComponent from '../../systemico.ui/components/banners/BannerMultiProductComponent.vue'
import CookieConsent from '../../systemico.ui/components/headers/CookieConsent.vue'

export default {
  name: 'App',
  components: {
    BannerMultiProductComponent,
    AccessibilityToolbar,
    HeaderSystemicoComponent,
    CookieConsent
  },
  metaInfo: {
    meta: [
      { name: 'description', content: 'Creamos y generamos experiencias que transforman empresas con tecnología innovadora. We create software for startups and small business. Latam Software Development, Technologies for small businesss' },
      { name: 'keywords', content: 'Desarrollo de Software, Aplicaciones Empresariales, Desarrollo de software Bucaramanga, Desarrollo de software Tunja, Desarrollo de software Barrancabermeja, Desarrollo de software Bogotá, Desarrollo de software Cali, Desarrollo de software Florencia, Desarrollo de software Villavicencio, Desarrollos de Apps, Desarrollos de Software, Desarrollo a la medida, Desarrollos Web, Software para transporte, Software para empresas de transporte, Software para pqrs, Software para proveedores, Software para gestión de Proveedores, Plataforma para Enviar SMS, Software para automatizar Whatsapp, Aplicación para enviar SMS, Desarrollo de software para emprendedores, Soporte de Software, Fabrica de Software, Software para pymes, Software Development Latam, Desarrollo de aplicaciones, Desarrollo de bots, Desarrollo API, Aplicaciones para Pymes, Tecnologías para pymes' }
    ]
  },
  data ( ) {
    return {
      lang: 'EN'
    }
  },
  beforeMount () {
    this.lang = window.navigator.language
    this.lang = this.lang.toUpperCase().split('-')[0]
    console.log("idioma "+this.lang)
  }
}
</script>

<style lang="scss">
@import 'assets/styles/main.scss';
@import '../../systemico.ui/assets/styles/main.scss';
html{
  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
}
*{
  font-family: 'Quicksand', sans-serif;
}
.app{
  margin-top:2rem;
}
</style>
