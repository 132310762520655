<script>
export default {
  name: "WavesComponent",
  props: {
    style: {
      type: Number,
      default: 1
    },
    background: {
      type: String,
      default: "#FFFFFF"
    },
    color: {
      type: String,
      default: "#000000"
    }
  },
}
</script>

<template>
  <b-container class="p-0 m-0 border" fluid >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path :fill="$props.color" fill-opacity="1" d="M0,256L45,256L90,96L135,192L180,32L225,64L270,256L315,160L360,288L405,160L450,128L495,64L540,288L585,320L630,32L675,64L720,256L765,32L810,160L855,96L900,288L945,192L990,192L1035,128L1080,64L1125,32L1170,320L1215,96L1260,0L1305,0L1350,256L1395,64L1440,128L1440,0L1395,0L1350,0L1305,0L1260,0L1215,0L1170,0L1125,0L1080,0L1035,0L990,0L945,0L900,0L855,0L810,0L765,0L720,0L675,0L630,0L585,0L540,0L495,0L450,0L405,0L360,0L315,0L270,0L225,0L180,0L135,0L90,0L45,0L0,0Z"></path></svg>
    <!--<svg  v-if="style === 1" id="visual" viewBox="0 0 100% 150" width="100%" height="150" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="100%" height="150" :fill="$props.background"></rect><path d="M0 20L49 20L49 65L98 65L98 55L148 55L148 45L197 45L197 60L246 60L246 80L295 80L295 49L345 49L345 82L394 82L394 110L443 110L443 82L492 82L492 102L542 102L542 69L591 69L591 43L640 43L640 65L689 65L689 99L738 99L738 98L788 98L788 85L837 85L837 70L886 70L886 49L935 49L935 30L985 30L985 97L1034 97L1034 77L1083 77L1083 91L1132 91L1132 57L1182 57L1182 40L1231 40L1231 76L1280 76L1280 24L1280 0L1280 0L1231 0L1231 0L1182 0L1182 0L1132 0L1132 0L1083 0L1083 0L1034 0L1034 0L985 0L985 0L935 0L935 0L886 0L886 0L837 0L837 0L788 0L788 0L738 0L738 0L689 0L689 0L640 0L640 0L591 0L591 0L542 0L542 0L492 0L492 0L443 0L443 0L394 0L394 0L345 0L345 0L295 0L295 0L246 0L246 0L197 0L197 0L148 0L148 0L98 0L98 0L49 0L49 0L0 0Z" :fill="$props.color" stroke-linecap="square" stroke-linejoin="miter"></path></svg>
    <svg v-if="style === 2" id="visual" viewBox="0 0 1280 150" width="1280" height="150" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="1280" height="150" :fill="$props.background"></rect><path d="M0 20L49 65L98 55L148 45L197 60L246 80L295 49L345 82L394 110L443 82L492 102L542 69L591 43L640 65L689 99L738 98L788 85L837 70L886 49L935 30L985 97L1034 77L1083 91L1132 57L1182 40L1231 76L1280 24L1280 0L1231 0L1182 0L1132 0L1083 0L1034 0L985 0L935 0L886 0L837 0L788 0L738 0L689 0L640 0L591 0L542 0L492 0L443 0L394 0L345 0L295 0L246 0L197 0L148 0L98 0L49 0L0 0Z" fill="#000000" stroke-linecap="square" stroke-linejoin="bevel"></path></svg>
    <svg v-if="style === 3" id="visual" viewBox="0 0 1280 150" width="1280" height="150" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="1280" height="150" :fill="$props.background"></rect><path d="M0 20L8.2 27.5C16.3 35 32.7 50 49 55.8C65.3 61.7 81.7 58.3 98.2 55C114.7 51.7 131.3 48.3 147.8 49.2C164.3 50 180.7 55 197 60.8C213.3 66.7 229.7 73.3 246 71.5C262.3 69.7 278.7 59.3 295.2 59.7C311.7 60 328.3 71 344.8 81.2C361.3 91.3 377.7 100.7 394 100.7C410.3 100.7 426.7 91.3 443 90C459.3 88.7 475.7 95.3 492.2 93.2C508.7 91 525.3 80 541.8 70.2C558.3 60.3 574.7 51.7 591 51C607.3 50.3 623.7 57.7 640 67C656.3 76.3 672.7 87.7 689 93.2C705.3 98.7 721.7 98.3 738.2 96C754.7 93.7 771.3 89.3 787.8 84.7C804.3 80 820.7 75 837 69C853.3 63 869.7 56 886 49.3C902.3 42.7 918.7 36.3 935.2 44.3C951.7 52.3 968.3 74.7 984.8 82.5C1001.3 90.3 1017.7 83.7 1034 82.7C1050.3 81.7 1066.7 86.3 1083 83C1099.3 79.7 1115.7 68.3 1132.2 59.8C1148.7 51.3 1165.3 45.7 1181.8 48.8C1198.3 52 1214.7 64 1231 61.3C1247.3 58.7 1263.7 41.3 1271.8 32.7L1280 24L1280 0L1271.8 0C1263.7 0 1247.3 0 1231 0C1214.7 0 1198.3 0 1181.8 0C1165.3 0 1148.7 0 1132.2 0C1115.7 0 1099.3 0 1083 0C1066.7 0 1050.3 0 1034 0C1017.7 0 1001.3 0 984.8 0C968.3 0 951.7 0 935.2 0C918.7 0 902.3 0 886 0C869.7 0 853.3 0 837 0C820.7 0 804.3 0 787.8 0C771.3 0 754.7 0 738.2 0C721.7 0 705.3 0 689 0C672.7 0 656.3 0 640 0C623.7 0 607.3 0 591 0C574.7 0 558.3 0 541.8 0C525.3 0 508.7 0 492.2 0C475.7 0 459.3 0 443 0C426.7 0 410.3 0 394 0C377.7 0 361.3 0 344.8 0C328.3 0 311.7 0 295.2 0C278.7 0 262.3 0 246 0C229.7 0 213.3 0 197 0C180.7 0 164.3 0 147.8 0C131.3 0 114.7 0 98.2 0C81.7 0 65.3 0 49 0C32.7 0 16.3 0 8.2 0L0 0Z" :fill="$props.color" stroke-linecap="round" stroke-linejoin="miter"></path></svg>
    <svg v-if="style === 4" id="visual" viewBox="0 0 1280 150" width="1280" height="150" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="1280" height="150" :fill="$props.background"></rect><path d="M0 56L160 56L160 92L320 92L320 109L480 109L480 50L640 50L640 106L800 106L800 51L960 51L960 98L1120 98L1120 112L1280 112L1280 26L1280 0L1280 0L1120 0L1120 0L960 0L960 0L800 0L800 0L640 0L640 0L480 0L480 0L320 0L320 0L160 0L160 0L0 0Z" :fill="$props.color" stroke-linecap="square" stroke-linejoin="miter"></path></svg>
    <svg v-if="style === 5" id="visual" viewBox="0 0 1280 150" width="1280" height="150" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="1280" height="150" :fill="$props.background"></rect><path d="M0 56L160 92L320 109L480 50L640 106L800 51L960 98L1120 112L1280 26L1280 0L1120 0L960 0L800 0L640 0L480 0L320 0L160 0L0 0Z" :fill="$props.color" stroke-linecap="square" stroke-linejoin="bevel"></path></svg>
    <svg v-if="style === 6" id="visual" viewBox="0 0 1280 150" width="1280" height="150" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><rect x="0" y="0" width="1280" height="150" :fill="$props.background"></rect><path d="M0 56L26.7 62C53.3 68 106.7 80 160 88.8C213.3 97.7 266.7 103.3 320 96.3C373.3 89.3 426.7 69.7 480 69.2C533.3 68.7 586.7 87.3 640 87.5C693.3 87.7 746.7 69.3 800 68C853.3 66.7 906.7 82.3 960 92.5C1013.3 102.7 1066.7 107.3 1120 95.3C1173.3 83.3 1226.7 54.7 1253.3 40.3L1280 26L1280 0L1253.3 0C1226.7 0 1173.3 0 1120 0C1066.7 0 1013.3 0 960 0C906.7 0 853.3 0 800 0C746.7 0 693.3 0 640 0C586.7 0 533.3 0 480 0C426.7 0 373.3 0 320 0C266.7 0 213.3 0 160 0C106.7 0 53.3 0 26.7 0L0 0Z" :fill="$props.color" stroke-linecap="round" stroke-linejoin="miter"></path></svg>-->
  </b-container>
</template>

<style scoped lang="scss">

</style>